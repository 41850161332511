import { io } from 'socket.io-client';
import { showNotification } from './Helpers';

// Usar la URL base del backend para conectar Socket.io
const socketUrl = import.meta.env.VITE_API_BASE_URL;

const socket = io(socketUrl, {
    withCredentials: true,
    autoConnect: false,
});

// Función para registrar al usuario en Socket.io
export const registerSocket = (userId) => {
    if (!socket.connected) {
        socket.connect();
    }
    socket.emit('register', userId);
};

// Manejar notificaciones entrantes
export const onNotification = (callback) => {
    socket.on('notification', (notification) => {
        showNotification('info', notification.Titulo, notification.Mensaje); // Mostrar la notificación usando showNotification
        callback(notification); // Ejecutar el callback proporcionado
    });
};

// Función para desconectar el socket
export const disconnectSocket = () => {
    if (socket.connected) {
        socket.disconnect();
    }
};

export default socket;
