/* eslint-disable no-unused-vars */

async function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('tokenDatabase', 2);

    request.onerror = (event) => {
      reject('Database failed to open');
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains('tokens')) {
        db.createObjectStore('tokens', { keyPath: 'id' });
      }
    };
  });
}

//________________________AUTH TOKEN________________________
export async function storeToken(token) {
  const db = await openDatabase();
  const transaction = db.transaction(['tokens'], 'readwrite');
  const store = transaction.objectStore('tokens');
  store.put({ id: 'authToken', value: token });
}

export async function getToken() {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['tokens'], 'readonly');
    const store = transaction.objectStore('tokens');
    const request = store.get('authToken');

    request.onsuccess = () => {
      resolve(
        request.result ? request.result.value : null
      );
    };

    request.onerror = () => {
      reject('Failed to get token');
    };
  });
}

export async function deleteToken() {
  const db = await openDatabase();
  const transaction = db.transaction(['tokens'], 'readwrite');
  const store = transaction.objectStore('tokens');
  store.delete('authToken');
}

//________________________REFRESH________________________
export async function storeRefreshToken(refreshToken) {
  const db = await openDatabase();
  const transaction = db.transaction(['tokens'], 'readwrite');
  const store = transaction.objectStore('tokens');
  store.put({ id: 'refreshToken', value: refreshToken });
}

export async function getRefreshToken() {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['tokens'], 'readonly');
    const store = transaction.objectStore('tokens');
    const request = store.get('refreshToken');

    request.onsuccess = () => {
      resolve(request.result ? request.result.value : null);
    };

    request.onerror = () => {
      reject('Failed to get refresh token');
    };
  });
}

export async function deleteRefreshToken() {
  const db = await openDatabase();
  const transaction = db.transaction(['tokens'], 'readwrite');
  const store = transaction.objectStore('tokens');
  store.delete('refreshToken');
}


//________________________ROLES_________________________
export async function storeRole(role) {
  const db = await openDatabase();
  const transaction = db.transaction(['tokens'], 'readwrite');
  const store = transaction.objectStore('tokens');
  store.put({ id: 'userRole', value: role });
}

export async function getRole() {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['tokens'], 'readonly');
    const store = transaction.objectStore('tokens');
    const request = store.get('userRole');

    request.onsuccess = () => {
      resolve(request.result ? request.result.value : null);
    };

    request.onerror = () => {
      reject('Failed to get role');
    };
  });
}

export async function deleteRole() {
  const db = await openDatabase();
  const transaction = db.transaction(['tokens'], 'readwrite');
  const store = transaction.objectStore('tokens');
  store.delete('userRole');
}

//________________________NAME_________________________
export async function storeName(name) {
  const db = await openDatabase();
  const transaction = db.transaction(['tokens'], 'readwrite');
  const store = transaction.objectStore('tokens');
  store.put({ id: 'userName', value: name });
}

export async function getName() {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['tokens'], 'readonly');
    const store = transaction.objectStore('tokens');
    const request = store.get('userName');

    request.onsuccess = () => {
      resolve(request.result ? request.result.value : null);
    };

    request.onerror = () => {
      reject('Failed to get name');
    };
  });
}

export async function deleteName() {
  const db = await openDatabase();
  const transaction = db.transaction(['tokens'], 'readwrite');
  const store = transaction.objectStore('tokens');
  store.delete('userName');
}

//________________________PERMISOS_________________________
export async function storePermisos(permisos) {
  const db = await openDatabase();
  const transaction = db.transaction(['tokens'], 'readwrite');
  const store = transaction.objectStore('tokens');
  store.put({ id: 'permisos', value: permisos });
}

export async function getPermisos() {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['tokens'], 'readonly');
    const store = transaction.objectStore('tokens');
    const request = store.get('permisos');

    request.onsuccess = () => {
      resolve(request.result ? request.result.value : null);
    };

    request.onerror = () => {
      reject('Failed to get permisos');
    };
  });
}

export async function deletePermisos() {
  const db = await openDatabase();
  const transaction = db.transaction(['tokens'], 'readwrite');
  const store = transaction.objectStore('tokens');
  store.delete('permisos');
}